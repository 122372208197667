@charset "UTF-8";
@use "settings" as s;


.kv{
	font-size: 7.3rem;
	letter-spacing: .053em;
	text-transform: uppercase;
	color: #fff;
	width: 100%;
	padding-top: 45.8041%;
	background-size: cover;
	position: relative;
	// display: flex;
	// justify-content: center;
	// align-items: center;
	&__head{
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translateX(-50%) translateY(-50%);
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		@include s.mq(w767){
			font-size: 5rem;
		}
		&--ja{
			font-size: 1.4rem;
			letter-spacing: .28em;
			margin-top: 18px;
			@include s.mq(w767){
				margin-top: 10px;
			}
		}
	}
}
