$color: #000;
$linkmark: #29a9e0;
$key: #ff7f00;


//font-family

html {
	font-family: 'Noto Sans JP', sans-serif;
	font-size: 62.5%;
}
html, body {
	height: 100%;
	margin: 0;
	padding: 0;
	-ms-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
}
body{
	//フォントレンダリング設定: 1x解像度ではsubpixel、2x以上の解像度ではgrayscale
	-webkit-font-smoothing: subpixel-antialiased;
	-moz-osx-font-smoothing: unset;
	color: $color;
	font-feature-settings: "pkna" 1;
	text-align: justify;
	font-kerning: auto;
	font-weight: normal;
	font-size: 1.6em;
	//font-size: 14px;
	word-break : break-all;
	word-wrap: break-word;
	overflow-wrap: break-word;
	color: $color;
	//letter-spacing: .5px;
}

@media only screen and 
(-webkit-min-device-pixel-ratio: 2),
(min-resolution: 2dppx) {
	body {
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}
}
*,
::before,
::after{
	box-sizing: border-box;
}
p{
	//margin-bottom: 1em;
	line-height: 1.6;
}
img{
	vertical-align: middle;
	width: 100%;
	height: auto;
}
a{
	text-decoration: none;
	color: #308bbb;
	-webkit-transition: opacity 200ms ease-out;
	-moz-transition: opacity 200ms ease-out;
	transition: opacity 200ms ease-out;
    &:hover{
    	text-decoration: none;
		opacity: .7;
	}
	img{
		-webkit-transition: opacity 200ms ease-out;
		-moz-transition: opacity 200ms ease-out;
		transition: opacity 200ms ease-out;
	    &:hover{
			opacity: .7;
		}
	}
}
table{
	width: 100%;
}
th{
	font-weight: bold;
}


//clearfix

.cf:after {
    content: "";
	display: block;
	clear: both;
	height: 0;
	visibility: hidden;
}

 // * For IE 6/7 only
 // * Include this rule to trigger hasLayout and contain floats.
 
// .cf {
//     *zoom: 1;
// }

