@charset "UTF-8";
@use "settings" as s;


.en{
	font-family: acumin-pro-extra-condensed,sans-serif;
	font-weight: 300;
	font-style: normal;
}
.entxt{
	font-family: acumin-pro-condensed,sans-serif;
	font-weight: 500;
	font-style: normal;
}
.ja{
	font-family: dnp-shuei-mincho-pr6n, sans-serif;
	font-weight: 400;
	font-style: normal;
}

//menu

.menu{
	position: fixed;
	width: 45px;
	height: 45px;
	top: 0;
	right: 0;
	cursor: pointer;
	z-index: 10000;
	background: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	// border-radius: 50%;
	opacity: 0.6;
	//transition: all .2s;
	display: none;
	//transform: translateY(5px);
	&__border{
		display: block;
		width: 28px;
		height: 2px;
		background: #000;
		transition: all .2s;
	}
	&__border + &__border{
		margin-top: 6px;
	}
	&__txt{
		text-align: center;
		margin: 0;
		line-height: 1;
		margin-top: 2px;
	}
}



/*! purgecss start ignore */


.menu.active{
	.menu__border--1{
		transform: rotate(-45deg) translateY(3px) translateX(-3px);
		// transform-origin:center center;
	}
	.menu__border--2{
		display: none;
	}
	.menu__border--3{
		transform: rotate(45deg) translateY(-3px) translateX(-3px);
		// transform-origin:center center;
	}
}



.cart{
	position: fixed;
	width: 45px;
	height: 45px;
	top: 0;
	right: 46px;
	cursor: pointer;
	z-index: 10000;
	background: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	opacity: 0.6;
	display: none;
	&__img{
		width: 24px;
	}
}
/*! purgecss end ignore */

.gnav{
	display: none;
	background: #000;
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1000;
	&__list{
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		&--sns{
			display: flex;
			justify-content: center;
		}
		&--cat{
			display: flex;
			justify-content: center;
			margin-top: 16px;
		}
	}
	&__item{
		color: #fff;
		font-size: 2.2rem;
		letter-spacing: .2em;
		text-align: center;
		&--insta{
			width: 31.5px;
			margin-right: 22px;
		}
		&--fb{
			width: 32px;
		}
		&--cat{
			color: #fff;
			font-size: 1.4rem;
			&.entxt{
				letter-spacing: 0;
				text-transform: uppercase;
			}
		}
		&--cat + &--cat{
			margin-left: 39px;
		}
	}
	&__item + &__item{
		margin-top: 60px;
	}
	&__link{
		color: #fff;
	}
}

.wrapper{
	//max-width: 1680px;
	display: flex;
	// margin: auto;
	// @include settings.mq(w767){
	// 	width: 700px;
	// 	padding: 0 15px;
	// }
	//display: none;
	@include s.mq(w767){
		display: block;
	}
}

//header

.header{
	width: 225px;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	background: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
	//padding-top: 10vh;
	&__logo{
		width: 88px;
		transform: translateY(-40px);
	}
}

//main
.main{
	width: calc(100% - 225px);
	transform: translateX(225px);
	@include s.mq(w767){
		width: 100%;
		transform: translateX(0);
	}
}


//footer

.footer{
	background: #000;
	margin-top: 160px;
	padding-top: 45px;
	padding: 45px 20px 82px 20px;
	position: relative;
	&__logo{
		width: 116px;
		margin: auto;
	}
	&nav{
		margin-top: 36px;
		&__list{
			display: flex;
			justify-content: center;
			@include s.mq(w587){
				flex-wrap: wrap;
			}
		}
		&__item{
			font-size: 1.4rem;
			color: #fff;
			@include s.mq(w587){
				margin-bottom: 10px;
			}
		}
		&__item + &__item{
			margin-left: 40px;
			@include s.mq(w980){
				margin-left: 20px;
			}
			// @include s.mq(w587){
			// 	margin-left: 13px;
			// }
		}
		&__link{
			color: #fff;
		}
		&__sns{
			display: flex;
			justify-content: center;
			margin-top: 20px;
			&-link{
				width: 27px;
			}
			&-item + &-item{
				margin-left: 17px;
			}
		}

	}
	.privacy{
		text-align: center;
		a{
			font-size: 1.4rem;
			color: #fff;
		}	
	}
	small{
		display: block;
		text-align: center;
		color: #fff;
		font-size: 1.2rem;
		margin-top: 47px;
	}
	.pagetop{
		position: absolute;
		top: -65px;
		//bottom: 0;
		left: 0;
		right: 0;
		height: 8px;
		display: block;
		width: 15px;
		margin: auto;

	}
}

.page{
	.header{
		@include s.mq(w767){
			display: none;
		}
	}
	.kv{
		background-size: cover;
	}
}

.link{
	margin-top: 130px;
	&__btn{
		// display: block;
		width: 200px;
		height: 44px;
		margin: auto;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 1.5rem;
		border: 1px solid #000;
		color: #000;
	}
}